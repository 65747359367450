/** 
 * 娱乐（VR直播）
 */
<template>
  <v-card class="vrLiveBroadcast">
      <v-banner
      single-line
      height="500"
      class="banner"
    >
          <v-img
            src="https://h5.ophyer.cn/official_website/banner/vr-ban-01.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">VR直播</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                    虚拟IP，语音互动，自定义720VR场景，想在哪直播就在哪直播
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/vr-ban-02.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <!-- 2.产品功能 -->
    <div class="d-flex justify-center pb-8" style="width:1200px;margin:0 auto;">
      <v-card class="tabWrap warp-box max-width-1200" flat >
        <div class="public-title" style="padding-bottom:28px;">
          <p>PRODUCT SYSTEM</p>
          <div>
            <span>产品功能</span>
          </div>
        </div>
        <v-tabs class="g-tabs justify-center d-flex mb-4" v-model="tab">
          <v-tabs-slider color="#0084FF"></v-tabs-slider>
          <v-tab v-for="item in data.wrap1.items" :key="item.title" >
            {{item.h1}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item,index) in data.wrap1.items"
            :key="index"
            :transition="false"
          >
          <v-card color="basil"  flat class="itemStyle d-flex flex-no-wrap justify-center align-center">
            <transition name="slide-fade" appear v-show="tab == index">
                <v-card width="392" flat class="pa-0" v-show="tab == index">
                  <v-card-title v-text="item.title" class="tabTitle font-size-24 pa-0 "></v-card-title>
                  <v-card-text v-text="item.content" class="tabNew font-size-14 pa-0 mt-4"></v-card-text>
                  <v-card-text v-text="item.content1" class="tabNew font-size-14 pa-0"></v-card-text>
                </v-card>
              </transition>
              <transition name="slide-fade-reverse" appear>
              <div v-show="tab == index">
                <v-img 
                  class="tabImg"
                  style="border-radius:10px;"
                  max-height="260" max-width="400"
                  min-width="400" min-height="260"
                  :src="item.src"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </div>
              </transition>
          </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!-- VR产品优势 -->
    <v-container class="product-part2  pb-15 py-8 pt-4" style="background: #FBFBFB;">
      <div class="public-title" style="margin-top:50px">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>产品优势</span>
        </div>
      </div>
      <v-container  class="d-flex justify-center pt-8" style="margin-top:30px;">
        <div  v-for="item in data.wrap2.content" :key="item.id" >
          <v-card class="mx-5 px-5 pb-2" flat>
              <div class="d-flex justify-center">
                <v-img 
                  :src="item.img" 
                  max-width="120" min-width="120" max-height="120" min-height="120"
                  style="border-radius:5px"
                ></v-img>
              </div>
              <v-card-title class="tit  mt-7 pa-0" v-text="item.cardTitle"></v-card-title>
              <v-card-text  class="text text-align-center pa-0 mt-5 font-size-14" v-text="item.h2"></v-card-text>
            </v-card>
        </div>
        <!-- <v-row class="ma-0 mt-10">
          <v-col v-for="item in data1.wrap2.content" :key="item.id" cols="4">
            <v-card class="px-8" flat>
              <div class="d-flex justify-center">
                <v-img 
                  :src="item.img" 
                  max-width="120" min-width="120" max-height="120" min-height="120"
                  style="border-radius:5px"
                ></v-img>
              </div>
              <v-card-title class="tit  mt-7 pa-0" v-text="item.cardTitle"></v-card-title>
              <v-card-text  class="text text-align-center pa-0 mt-5 font-size-14" v-text="item.h2"></v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js'

let _this;
export default {
  name: "vrLiveBroadcast",
  data(){
    return{
      data: contentData.vrLiveBroadcast,
      data1:contentData.VRSceneShow,
      detailIndex: 1,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
      vtab: null,
    
    }
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.public-title{
  height: unset;
  margin-bottom: 0px;
}
.warp {
  width: 1200px;
  margin: 0 auto;
  img{
    border-radius: 15px;
  }
}
.product-part2{
  // max-width: 1200px;
  // margin: 0 auto;
  img{
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  .text{
    width: 266px;
    margin: 0 auto;
  }
  .tit{
    font-weight: 500;
    font-size: 20;
    justify-content: center;
    color: #333;
  }
}
</style>